/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'assets/css/fonts/stylesheet.scss';

@mixin baseTabs {
    .active {
        button {
            background-color: var(--sunrise);
            color: var(--snow);
            border: none;
        }
        border-bottom: none;
    }

    button {
        width: 254px;
        color: var(--sky);
        background-color: var(--snow);
        border-radius: 20px;
        box-sizing: border-box;
        border: 1px solid var(--sky);

        &:hover {
            border: 1px solid var(--sky);
            color: var(--sky);
        }
    }
}
/* You can add global styles to this file, and also import other style files */
body {
    padding: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    margin: 0;

    --blue: #0087ca;
    --blue-medium: #496fc0;
    --blue-ligth-normal: #6293fc;
    --blue-ligth: #56ccf2;
    --blue-extra-ligth: #d2e1ff;
    --white: #ffffff;
    --black: #032821;

    --red: #eb5757;
    --orange: #f2994a;
    --yellow: #f2c94c;

    --green-medium: #6fcf97;

    --natural: #f5f5f5;

    --gray-normal: #828282;
    --gray-light: #d8d8d8;
    --gray-extra-light: #e8e8e8;

    --heightDefaultConsult: 124px;
    --heightDefaultExams: 195px;

    --underweight: rgba(85, 179, 247, 0.11);
    --normal: rgba(30, 197, 137, 0.11);
    --overweight: rgba(252, 139, 63, 0.21);
    --obese: rgba(252, 139, 63, 0.31);
    --extreme-obese: rgba(220, 53, 69, 0.11);

    --avenirPro: 'Avenir Next LT Pro';
    --avenirStd: 'Avenir LT Std';

    --snow: #ffffff;
    --water: #11c4d1;
    --sky: #079ee8;
    --sky-dark: #075ede;
    --sunrise: #e26f20;
    --gray: #58595b;
    --green: #07c969;

    padding: 0;
    font-family: var(--avenirStd);
    font-size: 1rem;
    margin: 0;
    overflow-x: hidden;

    *:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.sky {
    color: var(--sky);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 0;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

h1,
h2 {
    font-style: var(--avenirStd);
    font-weight: 750;
    font-size: 64px;
    line-height: 73px;
    font-style: normal;

    @media (max-width: 764px) {
        font-size: 41px;
        line-height: 42px;
    }
}

h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.02em;
}

h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.02em;
}

h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.01em;
}

h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
}

p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
}

a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: var(--black);
}

figure {
    margin: 0;
}

button {
    background: transparent;
    border: 0;
}

.body1 {
    font-family: var(--avenirStd);
    color: var(--snow);
    font-weight: 400;
    line-height: 31.62px;
    font-size: 26px;

    @media (max-width: 764px) {
        font-size: 15.5px;
        line-height: 18.8px;
    }
}
.desktop {
    @media (max-width: 768px) {
        display: none !important;
    }
}
.mobile {
    display: none !important;
    @media (max-width: 768px) {
        display: flex !important;
        justify-content: center;
    }
}
.page {
    position: relative;
    min-height: 480px;
    padding-top: 64px;

    @media screen and (min-width: 1200px) {
        padding-top: 90px;
    }
}

.container {
    max-width: 1470px;
    width: 100%;
}

.form-group {
    margin-bottom: 25px;
    input,
    select {
        font: {
            family: var(--avenirStd);
            weight: 500;
            size: 14px;
        }
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--sky);
        color: var(--gray);
        outline: none;
        height: 56px;
        line-height: 1;
        padding: 12px 0 10px 15px;

        &:focus {
            box-shadow: none !important;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .default {
        input,
        select {
            font-style: normal;
            font-weight: 200;
            border-radius: 5px;
            outline: none;
            line-height: 1;
            font-size: 12px;
            padding: 12px 0 10px 15px;
            border: 1px solid var(--gray-light);
            min-width: 100px;
            width: auto;
            height: 30px;

            &:focus {
                box-shadow: none !important;
            }
        }

        select {
            padding: 6px 22px 6px 6px;
        }
    }

    label {
        width: 100%;
    }

    .control {
        &-label {
            font-style: normal;
            font-weight: bold;
            background: var(--white);
            position: absolute;
            left: 10px;
            top: -7px;
            padding: 0 9px;
            outline: none;
            text-transform: uppercase;
            font-size: 10px;
            color: var(--gray);
            z-index: 1;
        }

        &-error {
            color: var(--red);
            font-size: 12px;
            position: absolute;
            left: 12px;
            bottom: 10px;
        }

        @media (min-width: 560px) {
            &-label {
                font-size: 12px;
            }
        }
    }
}

.input-check {
    position: relative;
    padding-left: 33px;
    margin-bottom: 10px;

    label {
        font-size: 16px;
        line-height: 16px;

        letter-spacing: 0.4px;
        color: var(--sky);
        margin-bottom: 0;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:disabled {
            & ~ div {
                opacity: 0.3;
                cursor: no-drop;

                & ~ span {
                    opacity: 0.3;
                    cursor: no-drop;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 30px;
        width: 30px;
        border-radius: 2px;
        border: 2px solid var(--sky);

        &:after {
            content: '';
            position: absolute;
            display: none;

            left: 5px;
            top: 2px;
            width: 7px;
            height: 12px;
            border: solid var(--sky);
            border-width: 0 2px 2px 0;

            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    input[type='radio'] {
        & ~ .checkmark {
            border-radius: 50%;
        }
    }

    .control-error {
        padding: 0;
        color: var(--red);
        font-size: 12px;
        display: block;
    }

    &:hover input ~ .checkmark {
        background-color: var(--white);
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    /** hover checkbox **/
    &:hover {
        cursor: pointer;
    }
}

::placeholder {
    color: #bdbdbd;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #bdbdbd;
}

::-ms-input-placeholder {
    color: #bdbdbd;
}

.show-pass {
    position: absolute;
    right: 0;
    top: 14px;
    border: 0;
    background: transparent;

    &:after {
        content: '';
        position: absolute;
        left: 15px;
        top: 1px;
        width: 3px;
        height: 26px;
        background: #828282;
        transform: rotate(45deg);
    }

    &.active {
        &:after {
            content: none;
        }
    }

    figure {
        margin: 0;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black !important;
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--blue-medium);
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--white);

    &:hover {
        color: var(--white);
    }

    &:focus {
        box-shadow: none;
    }

    &.outline {
        background: var(--white);
        border: 1px solid var(--blue-medium);
        border-radius: 6px;
        color: var(--blue-medium);

        &:hover {
            color: var(--blue-medium);
        }
    }

    &.disabled {
        background: var(--gray-light);
        border-color: var(--gray-light);
        color: var(--white);
        cursor: no-drop;
    }

    &-xs {
        width: 106px;
        height: 24px;
        font-size: 12px;
    }

    &-small {
        border-radius: 8px;
        width: 200px;
        height: 32px;
        font-size: 12px;
    }

    &-medium {
        width: 200px;
        height: 48px;
        border-radius: 8px;
        font-size: 14px;
    }
}

.toast {
    $this: &;

    &-container {
        .ngx-toastr {
            border-left: 5px solid var(--blue-medium);
            //border-radius: 0 3px 3px 0 !important;
            color: var(--black) !important;
            background: var(--white);
            box-shadow: 0 2px 13px rgba(0, 0, 0, 0.27) !important;

            &.toast-info {
                border-color: var(--yellow);
            }

            &.toast-success {
                border-color: var(--green);
            }

            &.toast-error {
                border-color: var(--red);
            }

            .toast-title {
                font-weight: 600;
                color: var(--gray);
            }

            .toast-message {
                font-weight: 300;
                color: var(--gray);
            }

            button {
                color: var(--gray);
            }
        }
    }

    &-progress {
        background-color: var(--gray-light) !important;
    }
}

.custom-scroll {
    &::-webkit-scrollbar {
        width: 10px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--gray-extra-light);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--blue-medium);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--blue);
    }
}

.modal {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    padding: 0;

    &-backdrop {
        z-index: 1050 !important;
    }

    &-content {
        border: 0;
        border-radius: 22px;
        background: {
            color: transparent;
            position: center;
            size: cover;
            repeat: no-repeat;
        }
    }

    &-dialog {
        height: 100%;
        align-items: center;
        display: flex;
        margin: auto;
        max-width: 75%;
    }

    &-body {
        padding: 15px 30px;

        h5 {
            margin-bottom: 15px;
        }
    }

    &-footer {
        border: 0;
        padding: 0 15px 15px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        .btn {
            font-size: 12px;
            height: 24px;
            max-width: 185px;

            &.outline {
                border: 0;
            }
        }
    }

    .close {
        cursor: pointer;
        opacity: 1;
    }

    &-header,
    &-body,
    &-footer {
        background: transparent;
    }

    @media screen and (min-width: 768px) {
        &-dialog {
            max-width: 500px;
        }
        &-body {
            padding: 15px 60px;
        }
    }
    @media screen and (min-width: 1200px) {
        &-dialog {
            max-width: 1200px;
        }
        &-content {
            border-radius: 44px;
        }
        &-body {
            padding: 30px 125px;

            .btn {
                max-width: 385px;
                height: 44px;
            }
        }
        &-footer {
            padding: 0 30px 45px;
            .btn {
                max-width: 385px;
                height: 44px;
                font-size: 18px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

img {
    max-width: 100%;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

figure,
picture {
    margin: 0;
}

.text-underline {
    text-decoration: underline !important;
}

.content-form {
    padding-top: 30px;
    color: var(--white);

    h2 {
        font: {
            //family: var(--font-sharonsans-bold);
            size: 16px;
        }
    }
    h5 {
        font: {
            //family: var(--font-sharonsans-regular);
            size: 12px;
            weight: 400;
        }
    }

    @media screen and (min-width: 1200px) {
        h2 {
            font-size: 20px;
        }
        h5 {
            font-size: 15px;
        }
    }
}

.box {
    position: relative;
    background: var(--white);
    border-radius: 5px;
    padding: 18px;
    max-width: 1239px;
    width: 100%;
    margin: 0 auto;
    color: #032b23;

    .form-group {
        margin-bottom: 18px;

        input,
        textarea,
        select {
            border: 1px solid #032b23;
            color: var(--black);
        }
        .control-label {
            background-color: var(--white);
            color: #032b23;
        }
    }

    &-table {
        position: relative;
        max-width: 919px;
        border-radius: 12px;
        padding: 30px 0;
        margin: 30px auto 0;
        background: var(--white);
        color: #023429;
    }

    @media screen and (min-width: 1200px) {
        padding: 40px 80px;

        &-table {
            margin-top: 46px;
            border: 1px solid #023429;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            padding: 40px 63px;
        }
    }
}

.pagination {
    justify-content: center;
    margin-top: 30px;
    gap: 5px;

    .page-link {
        padding: 5px;
        border: none;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        outline: none;
        color: var(--white);
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        font: {
            family: var(--font-sharonsans-regular);
            size: 12px;
        }

        &:active,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    .page {
        &-item {
            &.active {
                .page-link {
                    background: var(--white);
                    color: #023429;
                }
            }

            &:first-child {
                .page-link {
                    border-radius: 8px 0 0 8px;

                    &:before {
                        content: '';
                        width: 7px;
                        height: 10px;
                        background: {
                            //image: url("assets/images/Arrow-Left.svg");
                            repeat: no-repeat;
                            position: center;
                            size: cover;
                        }
                    }
                }
            }

            &:last-child {
                .page-link {
                    border-radius: 0 8px 8px 0;

                    &:before {
                        content: '';
                        width: 7px;
                        height: 10px;
                        background: {
                            //image: url("assets/images/Arrow-Right.svg");
                            repeat: no-repeat;
                            position: center;
                            size: cover;
                        }
                    }
                }
            }

            &:first-child,
            &:last-child {
                .page-link {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(242, 242, 242, 0.2);
                    border-radius: 5px;

                    &:before {
                        display: block;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 140%;
                        color: var(--gray);
                    }
                    span {
                        display: none;
                    }
                }
            }

            &.disabled {
                cursor: no-drop;
                background: inherit;
                a {
                    &:before {
                        opacity: 0.3;
                    }
                }
            }
        }
    }
    .visually-hidden {
        display: none;
    }

    @media screen and (min-width: 768px) {
        .page-link {
            width: 34px;
            height: 34px;
            font-size: 15px;
        }
    }
}
